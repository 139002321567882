export const TECH_SKILLS = [
  "JavaScript",
  "React",
  "React Native",
  "Redux",
  "NPM",
  "Node.js",
  "Jest",
  "C#",
  ".NET",
  "Java",
  "Java Swing UI",
  "Python",
  "C++",
  "HTML",
  "CSS",
  "Bootstrap",
  "Bamboo",
  "Jira",
  "SQL",
  "MongoDB",
  "MSSQL",
  "Microsoft Power Automate",
  "Visual Studio Code",
  "Visual Studio",
  "Swagger / OpenAPI",
  "Postman",
  "REST APIs",
  // "Web Sockets",
  "PowerShell",
];
