import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React from "react";

const lightHeaderColor = "rgba(255, 255, 255, 0.25)";
const darkHeaderColor = "rgba(0, 0, 0, 0.25)";

export default function NavBar({ textColor }) {
  const LinkStyle = {
    color: textColor,
    marginLeft: "25px",
  };

  return (
    <Navbar
      collapseOnSelect="true"
      expand="lg"
      style={{
        paddingTop: "10px",
        backgroundColor:
          textColor === "black" ? lightHeaderColor : darkHeaderColor,
        zIndex: 10,
      }}
    >
      <Container
        style={{
          justifyContent: "flex-end",
          marginRight: "35px",
        }}
      >
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{
            backgroundColor: "white",
            marginBottom: "12px",
            marginTop: "12px",
          }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto" style={{ alignItems: "flex-end" }}>
            <Nav.Link style={LinkStyle} href="#aboutme">
              About Me
            </Nav.Link>
            <Nav.Link style={LinkStyle} href="#techstack">
              Tech Skills
            </Nav.Link>
            <Nav.Link style={LinkStyle} href="#portfolio">
              Portfolio
            </Nav.Link>
            <Nav.Link style={LinkStyle} href="#contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
