import React, { useContext } from "react";

import ThemeContext from "../context/ThemeContext";

const Footer = () => {
  const { theme } = useContext(ThemeContext);
  const FOOTER_THEME = theme === "dark-theme" ? "dark" : "light";
  const LOGO_COLOR = theme === "dark-theme" ? " white" : " ";
  return (
    <div id="contact" className={"footer " + FOOTER_THEME}>
      <a href="mailto:piovesan.robert@outlook.com">
        <button className={"email " + LOGO_COLOR}></button>
      </a>
      <a href="https://github.com/RobPiovesan">
        <button className={"github " + LOGO_COLOR}></button>
      </a>
      <a href="https://www.linkedin.com/in/robpiovesan/">
        <button className={"linkedin " + LOGO_COLOR}></button>
      </a>
    </div>
  );
};

export default Footer;
