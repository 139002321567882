import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";

const ThemeSelector = () => {
  const { theme } = useContext(ThemeContext);

  var TEXT_THEME =
    theme === "dark-theme"
      ? "segoe font-w-400 white"
      : "segoe font-w-400 black";

  return (
    <span className="theme-selector-container">
      <span className="theme-selector">
        <span className={TEXT_THEME}>TRY ME</span>
        <ThemeBox theme="light-theme"></ThemeBox>
        <ThemeBox theme="dark-theme"></ThemeBox>
      </span>
    </span>
  );
};

export default ThemeSelector;

const ThemeBox = (props) => {
  var themeClass = `theme-box ${props.theme}`;
  const { toggleTheme } = useContext(ThemeContext);
  return (
    <button
      className={themeClass}
      onClick={() => toggleTheme(props.theme)}
    ></button>
  );
};
