import React, { useContext } from "react";
import LogoComp from "./LogoComp";
import AboutMe from "./AboutMe";
import MyWork from "./MyWork";
import TechStack from "./TechStack";

import Footer from "./Footer";
import ThemeContext from "../context/ThemeContext";
import NavBar from "./NavBar";

const HeaderComp = () => {
  const { theme } = useContext(ThemeContext);

  const BACKGROUND_CLASS = `page bg-animation-${theme}`;
  const NAV_TEXT_CLASS = theme === "dark-theme" ? "white" : "black";

  return (
    <div>
      <div id="top" className={BACKGROUND_CLASS}>
        <NavBar textColor={NAV_TEXT_CLASS} />
        <LogoComp />
      </div>
      <AboutMe />
      <TechStack />
      <MyWork />
      <Footer />
    </div>
  );
};

export default HeaderComp;
