import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";
import headshot from "../assets/headshot1.jpg";

const AboutMe = () => {
  const { theme } = useContext(ThemeContext);

  var TEXT_THEME = theme === "dark-theme" ? "segoe white" : "segoe black";
  const BACKGROUND_CLASS = `page bg-animation-${theme}-2`;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        minHeight: "fit-content",
      }}
      id="aboutme"
    >
      <div
        id="top"
        className={BACKGROUND_CLASS}
        style={{
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "100%",

            position: "relative",
            display: "flex",
            flexWrap: "wrap",

            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              maxWidth: "80%",
              minHeight: "100vh",
              display: "flex",
              flexWrap: "wrap",
              paddingTop: 30,
              paddingBottom: 30,
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                width: "30%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                minWidth: 300,
              }}
            >
              <img
                src={headshot}
                style={{
                  borderRadius: "50% 50% 0px 50%",
                  width: "100%",
                  padding: 20,
                  paddingBottom: 0,
                }}
                alt="DisplayPhoto"
              ></img>
            </div>
            <div
              style={{
                width: "60%",
                minWidth: 300,
                padding: 20,
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                paddingTop: 30,
                paddingBottom: 30,
              }}
            >
              <h1>
                <span
                  className={TEXT_THEME}
                  style={{
                    fontSize: 70,
                    flexShrink: true,
                  }}
                >
                  ABOUT ME
                </span>
              </h1>
              <p>
                <span
                  className={TEXT_THEME}
                  style={{
                    flexShrink: true,
                    //backgroundColor: theme === "dark-theme" ? "white" : "black",
                    padding: 2,
                    margin: 0,
                  }}
                >
                  Hello, my name is Rob and I am a full stack Developer based in
                  Adelaide, South Australia. My passion lies in creating modern
                  and scalable applications, as well as building interfaces that
                  provide meaningful user experiences.
                  <br />
                  <br />
                  I discovered my love for software development while pursuing
                  my Bachelor's Degree in Information Technology. This field has
                  allowed me to channel both my problem-solving and creative
                  abilities. My passion has led me to undertake various personal
                  projects, including developing mobile applications, games,
                  Reddit bots, automated financial trackers, and building this
                  online portfolio, among many others.
                  <br />
                  <br />
                  With industry experience in building web applications, I have
                  worked with a range of modern technologies such as JavaScript
                  (<b>React, React Native, and Node.js</b>), Java (
                  <b>Java Spring</b>), and have experience working with both
                  relational and non-relational databases.
                  <br />
                  <br />
                  As an adaptable developer, I'm a firm believer in using the
                  right tool for the job. Over the years, I have gained
                  proficiency in several other programming languages and
                  frameworks such as <b>Python, PowerShell, C++, and C#,</b> and
                  I'm always keen to learn more!
                  <br />
                  <br /> In addition to my technical skills, I have studied
                  interaction, graphic, and UI/UX design, which has helped me
                  optimize my application designs and prioritize the user
                  experience.
                </span>
              </p>
            </div>
          </div>
          <button
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              backgroundColor: "#00000000",
              border: "none",
              marginRight: "40px",
              marginBottom: "40px",
            }}
          >
            <a href="#top">
              <div className={"up-arrow " + theme}></div>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
