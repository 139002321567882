import React, { useContext, Component } from "react";
import ThemeContext from "../context/ThemeContext";
import ThemeSelector from "./ThemeSelector";

export default class LogoComp extends Component {
  state = {
    options: ["DEVELOPED", "DESIGNED", "BUILT"],
    currentOption: 0,
    text: "DEVELOPED",
    direction: "DESC",
    pauseCount: 0,
    pauseMax: 10,
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      this.updateText(this.state);
    }, 100);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="vertical-center">
        <LogoHeading value={this.state.text} />
        <Logo />
        <ThemeSelector></ThemeSelector>
      </div>
    );
  }

  updateText = (state) => {
    var { options, currentOption, text, direction, pauseCount, pauseMax } =
      state;

    let currentOpt_ = currentOption;
    if (currentOption === options.length) currentOpt_ = 0;

    if (direction === "DESC") {
      if (text.length === 0) {
        currentOpt_++;
        this.setState({ currentOption: currentOpt_, direction: "ASC" });
      } else {
        this.setState({
          text: options[currentOption].substr(0, text.length - 1),
        });
      }
    }

    if (direction === "ASC") {
      if (text.length === options[currentOpt_].length) {
        if (pauseCount >= pauseMax) {
          this.setState({ direction: "DESC", pauseCount: 0 });
        } else {
          this.setState({ pauseCount: pauseCount + 1 });
        }
      } else {
        this.setState({
          text: options[currentOpt_].substr(0, text.length + 1),
        });
      }
    }

    this.setState({ currentOption: currentOpt_ });
  };
}

const LogoHeading = (props) => {
  const { theme } = useContext(ThemeContext);
  let className = "segoe margin-bottom logo-subtext ";
  className += theme === "dark-theme" ? "white " : "black ";

  return <div className={className}>{props.value + "|"}</div>;
};

const Logo = () => {
  const { theme } = useContext(ThemeContext);
  const className_ = `logo-lg ${theme}`;
  return <span className={className_}></span>;
};
