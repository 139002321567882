import HeaderComp from "./components/HeaderComp";
import React, { Component } from "react";
import "./App.css";
import ".";
import { ThemeProvider } from "./context/ThemeContext";
// import AnimatedCursor from "react-animated-cursor";

class App extends Component {
  toggleTheme = (theme) => {
    this.setState({ theme });
  };

  state = { theme: "light-theme", toggleTheme: this.toggleTheme };

  render() {
    return (
      <div className="App">
        {/* <AnimatedCursor
          color={this.state.theme === "light-theme" ? "0,0,0" : "255, 255, 255"}
          innerSize={14}
          outerSize={14}
        /> */}
        <ThemeProvider value={this.state}>
          <HeaderComp />
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
