import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";

const MyWork = () => {
  const { theme } = useContext(ThemeContext);
  const BG_CLASS =
    theme === "dark-theme"
      ? "page bg-animation-dark-theme-2 mywork"
      : "page bg-animation-light-theme-2 mywork";

  var TEXT_THEME = theme === "dark-theme" ? "segoe white" : "segoe black";
  return (
    <div id="portfolio" className="page">
      <div className={BG_CLASS}>
        <div style={{ padding: 20, paddingBottom: 120 }}>
          <div className={TEXT_THEME + " text-align-center text-xl"}>
            PORTFOLIO
          </div>
          <p className={"text-align-center " + TEXT_THEME}>
            This portfolio website is completely developed by myself using
            React, Bootstrap and various other JavaScript libraries.
            <br />
            For more examples of my work please feel free to look through the
            projects below.
          </p>
          <div
            className="centered-container"
            style={{ width: "100%", maxWidth: 900 }}
          >
            <div
              className="container"
              style={{
                width: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Card
                  title="Eatly"
                  desc={
                    <span>
                      A multiplatform social food application built using React
                      Native, Node.js and MongoDB.
                      <br />
                    </span>
                  }
                  buttons={[
                    {
                      link: "https://play.google.com/store/apps/details?id=com.robpiovesan.eatly",
                      title: "View on Google Play",
                    },
                    {
                      link: "https://apps.apple.com/us/app/eatly/id1609349289",
                      title: "View on App Store",
                    },
                  ]}
                />
                <Card
                  title="Live Chat"
                  desc={
                    <span>
                      A real-time web-chat application built using Web Sockets
                      with Node, Express and React.
                      <br />
                    </span>
                  }
                  buttons={[
                    {
                      link: "https://github.com/RobPiovesan/LiveChat",
                      title: "View on GitHub",
                    },
                  ]}
                />
                <Card
                  title="Caption AI"
                  desc={
                    <span>
                      A web app built on Azure which generates captions and tags
                      from images using computer vision AI.
                      <br />
                    </span>
                  }
                  buttons={[
                    {
                      link: "https://zealous-mushroom-024e83500.2.azurestaticapps.net/",
                      title: "Try it out",
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <button className="return-top-btn">
          <a href="#top">
            <div className={"up-arrow " + theme}></div>
          </a>
        </button>
      </div>
    </div>
  );
};

const Card = ({ title, desc, buttons }) => {
  return (
    <div
      className="card bg-dark"
      style={{
        display: "flex",
        flex: 1,
        minWidth: 250,
        margin: 10,
        height: 250,
      }}
    >
      <div className="card-body" style={{ flex: 1 }}>
        <div className="card-title white">{title}</div>
        <p className="card-text segoe white">{desc}</p>

        <div style={{ display: "flex", flexDirection: "column" }}>
          {buttons.map((button) => {
            return (
              <a
                href={button.link}
                target="_blank"
                rel="noreferrer"
                className="btn btn-sm btn-outline-info"
                style={{ margin: 10 }}
              >
                <div className="segoe">{button.title}</div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MyWork;
