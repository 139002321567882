import React, { useContext } from "react";
import ThemeContext from "../context/ThemeContext";

// Tech Stack Icon Imports
import jsLogo from "../assets/stack/JS.png";
import reactLogo from "../assets/stack/React.png";
import nodeLogo from "../assets/stack/Node.png";
import pythonLogo from "../assets/stack/Python.png";
import cSharp from "../assets/stack/csharp.png";
import dotnet from "../assets/stack/dotnet.png";
import html from "../assets/stack/html.png";
import java from "../assets/stack/Java.png";
import vscode from "../assets/stack/vscode.png";
import mongo from "../assets/stack/mongodb.png";
import git from "../assets/stack/git.png";
import postman from "../assets/stack/Postman.png";
import { Button } from "react-bootstrap";
import TechStackModal from "./TechStackModal";
import { useState } from "react";

const TechStackLogosLayer1 = [
  { logo: jsLogo, title: "JavaScript" },
  { logo: html, title: "HTML5" },
  { logo: java, title: "Java" },
  { logo: cSharp, title: "C#" },
  { logo: pythonLogo, title: "Python" },
];
const TechStackLogosLayer2 = [
  { logo: reactLogo, title: "React" },
  { logo: nodeLogo, title: "Node.js" },
  { logo: dotnet, title: ".NET" },
  { logo: mongo, title: "Mongo DB" },
];
const TechStackLogosLayer3 = [
  { logo: git, title: "git" },
  { logo: vscode, title: "Visual Studio Code" },
  { logo: postman, title: "Postman" },
];

const TechStackLogos = [
  TechStackLogosLayer1,
  TechStackLogosLayer2,
  TechStackLogosLayer3,
];

const lightTransparentBg = "rgba(255, 255, 255, 0.3)";
const darkTransparentBg = "rgba(255, 255, 255, 0.2)";

const TechStack = () => {
  const [showModal, setShowModal] = useState(false);
  const { theme } = useContext(ThemeContext);
  const BG_CLASS =
    theme === "dark-theme"
      ? "page bg-animation-dark-theme mywork"
      : "page bg-animation-light-theme mywork";

  var TEXT_THEME = theme === "dark-theme" ? "segoe white" : "segoe black";

  const baseImageStyle = {
    aspectRatio: "1/1",
    maxWidth: "180px",
    minWidth: "40px",
    padding: "4%",
  };

  const imageStyleSmall = {
    ...baseImageStyle,
    width: "40%",
  };

  const imageStyle = {
    ...baseImageStyle,
    width: "60%",
  };

  const imageStyleLarge = {
    ...baseImageStyle,
    width: "80%",
  };

  return (
    <div id="techstack" className="page">
      <div className={BG_CLASS}>
        <div
          style={{
            padding: 20,
            paddingBottom: 60,
            backgroundColor:
              theme === "dark-theme" ? darkTransparentBg : lightTransparentBg,
            borderRadius: "20px",
            margin: "50px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{ padding: "25px" }}
            className={TEXT_THEME + " text-align-center text-xl"}
          >
            TECH STACK & TOOLS
          </div>

          <div
            className="centered-container"
            style={{ width: "100%", maxWidth: 900 }}
          >
            <div
              className="container"
              style={{
                width: "100%",
              }}
            >
              {TechStackLogos.map((stack, index) => {
                let width;
                let style;

                switch (index) {
                  case 0:
                    width = "100%";
                    style = imageStyleLarge;
                    break;
                  case 1:
                    width = "80%";
                    style = imageStyle;
                    break;
                  case 2:
                    width = "60%";
                    style = imageStyleSmall;
                    break;
                  default:
                    width = "60%";
                    style = imageStyleSmall;
                }

                return (
                  <table
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginBottom: "4%",
                      width,
                    }}
                  >
                    <tr>
                      {stack.map(({ logo, title }) => {
                        return (
                          <td style={{ textAlign: "center" }}>
                            <img
                              style={style}
                              src={logo}
                              title={title}
                              alt={title}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  </table>
                );
              })}
            </div>
          </div>
          <br />
          <Button onClick={() => setShowModal(true)}>View all</Button>
        </div>
        <button className="return-top-btn">
          <a href="#top">
            <div className={"up-arrow " + theme}></div>
          </a>
        </button>
      </div>
      <TechStackModal
        show={showModal}
        setShow={setShowModal}
        title="I have experience with..."
      />
    </div>
  );
};

export default TechStack;
